import ScrollContainer from 'react-indiana-drag-scroll'
import axios from 'axios'
import { debounce } from 'lodash';
import { DateTime, Interval, Duration } from "luxon";

import Draggable from 'react-draggable';

import HeaderBar from './components/HeaderBar';

import { useState, useEffect, useRef, useMemo } from 'react';

import './App.scss';
import './Header.scss';


const TIMELINE_START = DateTime.fromObject({year: 2020, month: 4, day: 18, hour: 20, minute: 45 }, { zone: 'America/Halifax'});
const TIMELINE_END = DateTime.fromObject({year: 2020, month: 4, day: 19, hour: 19 }, { zone: 'America/Halifax'});
const i = Interval.fromDateTimes(TIMELINE_START, TIMELINE_END);
const dur = Duration.fromObject({minutes: 5});
const arrayOfTime = i.splitBy(dur);


const timelineSize = 1200;//4000; 
const leftPadding = 0;//halfwayPoint; //250;
const minimumEventWidth = 0.1;
const totalMinutes = i.length('minutes');

let isIOS = /iPad|iPhone|iPod/.test(navigator.platform)|| (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

function InfoPane({ timelines, currentDate, currentTime, activeEvents, clickedEvent, activateEvent, clearEvent, nextEvent, prevEvent }) {

  const [open, setOpen] = useState(false);

  if (clickedEvent.length !== 0) {

    const event = clickedEvent; 

    const startTimeObj = DateTime.fromISO(event.startTime, {setZone: true});
    let endTimeString;
    if (event.endTime) {
      const endTimeObj = DateTime.fromISO(event.endTime, {setZone: true});
      if (endTimeObj.hasSame(startTimeObj, 'day')) {
        endTimeString = <span> - { endTimeObj.toLocaleString(DateTime.TIME_WITH_SECONDS) }</span>;
      } else {
        endTimeString = <span> - { endTimeObj.toLocaleString({ month: 'long', day: 'numeric' }) }, { endTimeObj.toLocaleString(DateTime.TIME_WITH_SECONDS) }</span>;
      }
    }

    const arr = clickedEvent.id.split('-');
    const eventIndex = Number(arr[1]);

    let hasPrev = true;
    if (eventIndex === 0) {
      hasPrev = false;
    }

    const timelineIndex = arr[0];
    const nextEventIndex = eventIndex + 1;
    const timelineTotal = timelines[timelineIndex].events.length;

    //const event = timelines[timelineIndex].events[nextEventIndex];

    let hasNext = true;
    if (typeof timelines[timelineIndex].events[nextEventIndex] === 'undefined') {
      hasNext = false;
    }

  


    return (
      <div className={`infoPane ${open ? "infoPane--open" : ""}`}>

        <button className="infoPane__expand"
          onClick={
            () => { setOpen( !open ) }
          }>
          <span className="infoPane__expand__icon"></span>
        </button>


        <button type="button" className="infoPane__closeButton" onClick={clearEvent}>
          
          <span className="infoPane__closeButton__icon"></span>
          <span>Back to active events</span>
        </button>


        <div className="bottomNav">
        <h3>{timelines[timelineIndex].title }
            <span> {eventIndex + 1}/{timelineTotal}</span>
            </h3>
        <div className="navButtons">
          
              <button title="Go to previous event in this timeline" className="navButtons__previous" onClick={prevEvent} disabled={!hasPrev}>
                <span className="navButtons__previous__icon"></span>
              </button>
              <button title="Go to next event in this timeline" className="navButtons__next" onClick={nextEvent} disabled={!hasNext}>
                <span className="navButtons__next__icon"></span>
              </button>
            </div>

            
           
            
          </div>
        <div className="infoPane__inner">
          <h2>{event.title}</h2>
          <p className='infoPane__date infoPane__date--bold'>
            { startTimeObj.toLocaleString({ month: 'long', day: 'numeric' }) }, { startTimeObj.toLocaleString(DateTime.TIME_WITH_SECONDS) } 
            {endTimeString ? endTimeString : false }
            {event.isEstimate ? ' (estimated)' : false}
          </p>
          <p className="infoPane__location">{event.location}</p>
          <div className="infoPane__description" dangerouslySetInnerHTML={{ __html: event.description }}></div>
         

          <div className="infoPane__document">
            <h3>Additional Information</h3>
            
            <p><a href={ event.foundational_document_url } target="_blank" rel="noreferrer">Foundational Documents</a> share the key facts and events leading up to, during and after the mass casualty. Volume 2 of the <a href="https://masscasualtycommission.ca/final-report/" target="_blank" rel="noreferrer">Final Report</a> sets out the Commission’s main findings in the narrative of what happened leading up to, during, and in the aftermath of the mass casualty of April 18 and 19, 2020.</p>
       
            <div className="infoPane__document__icon">
              <span></span>
            </div>
          </div>


        </div>
          
      </div>
    )
  }

  if (activeEvents.length === 0) {
   

    return (
      <div className={`infoPane infoPane--full ${open ? "infoPane--open" : ""}`}>

        <button className="infoPane__expand"
          onClick={
            () => { setOpen( !open ) }
          }>
          <span className="infoPane__expand__icon"></span>
        </button>

       
        

          <h2>Active events occurring</h2>
          <h3 className="infoPane__activeTime">{currentDate} at {currentTime}</h3>
          <div className="infoPane__inner">
            
          <ul>
          <li>Events on the timeline that have a striped pattern are estimated times. Actual times have solid shading.</li>
          <li>Use the slider control to move along the timeline</li>
          <li>View individual event details using the information panel on the right (below on mobile devices)</li>
          <li>Use the navigation arrows to view the previous or next event</li>
          </ul>

  
        </div>
      </div>
     )
  }

  return (

    <div className={`infoPane infoPane--full ${open ? "infoPane--open" : ""}`}>

      <button className="infoPane__expand"
        onClick={
          () => { setOpen( !open ) }
        }>
        <span className="infoPane__expand__icon"></span>
      </button>


      <h2>Active events occurring</h2>
      <h3 className="infoPane__activeTime">{currentDate} at {currentTime}</h3>

      <div className="infoPane__inner">

      

      {timelines.map((timeline, index) => {
        
        let events = timeline.events.map((event, key) => {
          return (
            {id: `${index}-${key}`, title: event.title, location: event.location_description, description: event.description_html, isEstimate: event.is_start_time_estimated, foundational_document_url: timeline.foundational_document_url, startTime: event.start_time_iso8601, endTime: event.end_time_iso8601 }
          );
        });
        events = events.filter((event, key) => {
          return activeEvents.includes(`${index}-${key}`);
        });

        if (events.length !== 0) {
          return (
            <div>
              <h3 className="infoPane__timelineTitle">{timeline.title}</h3>
              <p>
                {events.map((event) => {
                  const startTimeObj = DateTime.fromISO(event.startTime, {setZone: true});
                  let endTimeString;
                  if (event.endTime) {
                    const endTimeObj = DateTime.fromISO(event.endTime, {setZone: true});
                    if (endTimeObj.hasSame(startTimeObj, 'day')) {
                      endTimeString = <span> - { endTimeObj.toLocaleString(DateTime.TIME_WITH_SECONDS) }</span>;
                    } else {
                      endTimeString = <span> - { endTimeObj.toLocaleString({ month: 'long', day: 'numeric' }) }, { endTimeObj.toLocaleString(DateTime.TIME_WITH_SECONDS) }</span>;
                    }
                  }
                  return (
                    <div className="infoPane__clickableEvent" onClick={() => {activateEvent(event)}}>

                      <p className='infoPane__clickableEvent__title'>{event.title}</p>
                      <p className='infoPane__date'>
                        { startTimeObj.toLocaleString({ month: 'long', day: 'numeric' }) }, { startTimeObj.toLocaleString(DateTime.TIME_WITH_SECONDS) } 
                        {endTimeString ? endTimeString : false }
                        {event.isEstimate ? ' (estimated)' : false}
                      </p>
                      <div className="infoPane__clickableEvent__icon">
                        <span></span>
                      </div>
                    </div>
                  )
                })}
              </p>
          </div>
          )
        } else {
          return false;
        }
      })}

      

      </div>

    </div>
  )
}


function Event({ event, clickedEvent, activateEvent, activeEvents, activateEvents, zoom, scrollPos }) {

  let isActive = false;
  // should use id
  if (clickedEvent.length !== 0) {
    if (clickedEvent.id === event.id) {
      isActive = true;
    }
  }



  const startTimeObj = DateTime.fromISO(event.startTime, {setZone: true});

  let widthPercentage = minimumEventWidth;

  if (event.endTime) {
    const endTimeObj = DateTime.fromISO(event.endTime, {setZone: true});
    const eventInterval = Interval.fromDateTimes(startTimeObj, endTimeObj);
    const eventMinutes = eventInterval.length('minutes');
  
    widthPercentage = eventMinutes / totalMinutes  * 100;

    if (widthPercentage < minimumEventWidth) {
      widthPercentage = minimumEventWidth;
    }

  } else {
    widthPercentage = minimumEventWidth;
  }

  // Time in minutes between timeline start and event start
  const eventI = Interval.fromDateTimes(TIMELINE_START, startTimeObj);
  const pxPerMinute =  ((timelineSize * zoom) - leftPadding) / i.length('minutes');
  const pxLeft = (pxPerMinute * (eventI.length('minutes')));
  const eventRef = useRef();

  useEffect(() => {
    if(eventRef.current) {
      const eventStartPos = eventRef.current.offsetLeft;
      const eventEndPos = eventRef.current.offsetLeft + eventRef.current.offsetWidth;

      if ( scrollPos > eventStartPos && scrollPos < eventEndPos) {
        activateEvents(event);
      }
    }
  }, [scrollPos, activateEvents, event])

  const outOfRange = Interval.fromDateTimes(TIMELINE_END, startTimeObj);
  if (!outOfRange.invalid) {
    return false;
  }


  return (
    <div className={`event ${event.isEstimate ? "event--estimate" : ""} ${isActive ? "event--active" : ""}`}
      style={{
        width:  widthPercentage + '%',
        left: pxLeft + 'px'
      }}
      ref={eventRef}
      onClick={(e) => { activateEvent(event, false) }}> 
    </div>
  )
}

function Timeline({ id, title, events, clickedEvent, activateEvent, activeEvents, activateEvents, zoom, scrollPos }) {

  
  let titleLeft = scrollPos - (window.outerWidth / 2);
  //console.log(window.innerWidth);
  let active = false;

  let firstEvent = events[0];

  if (clickedEvent.length !== 0) {
    const arr = clickedEvent.id.split('-');
    const timelineIndex = arr[0];
    if (id === timelineIndex) {
      active = true;
    }
  } else {
    //activeEvents
    if (activeEvents.length !== 0) {
      activeEvents.forEach(activeEvent => {
        const arr = activeEvent.split('-');
        const timelineIndex = arr[0];
        if (id === timelineIndex) {
          active = true;
        }
      });

    }
  }

  const timelineClick = () => {
    // activateEvent('1-0');
    // console.log(id);
    //onClick={(e) => { activateEvent(event) }}> 
  }

  return (
    <div className="timeline">
      <div onClick={(e) => { activateEvent(firstEvent) }} className={`timeline__title ${active ? "timeline__title--active" : ""}`} style={{ left: titleLeft + 'px'}} title="Click to scroll to beginning of this timeline">{title}</div>
      <div className="timeline__events">
      <div className="timeline__events__inner">
            {events.map((event, index) => (
              <Event key={`${id}-${index}`} event={event} clickedEvent={clickedEvent} activateEvent={activateEvent} activeEvents={activeEvents} activateEvents={activateEvents} zoom={zoom} scrollPos={scrollPos}/>
            ))}
        </div>
      </div>
    </div>
  );
}


function Indicator({time}) {
  const timeStr = time.toLocaleString({ timeStyle: 'short' });//DateTime.TIME_WITH_SECONDS
  return (
    <div className="indicator" style={{ flexGrow: '1' }}>
        <div className="indicator__inside">
          { timeStr }
        </div>
    </div> 
  );
}

function Warning({overlay, setOverlay}) {

  if (overlay === 1) { 
  return (
    <div className="overlay">
      <div className="overlay__inside">

      <h2>This webpage contains sensitive material.</h2>

      <p>Sometimes reading about distressing or emotionally overwhelming information can be challenging. As you read this report, please make sure to keep mental health and wellness in mind. If you or someone you know is in need of support, consider the supports listed below, or check with your local health authority or the <a href="https://cmha.ca/" target="_blank">Canadian Mental Health Association at cmha.ca</a> to find resources in your area.</p>

      <p>Please visit our <a href="https://masscasualtycommission.ca/support/">Wellness Support page</a> for more information on mental health resources.</p>
  
        <a href="https://masscasualtycommission.ca/support/" className="button">View Wellness Supports</a>
        <button className="button"
          onClick={
            () => { setOverlay(2) }
          }
        >Continue</button>
        <p>{overlay}/3</p>
      </div>
    </div>
  )
  } else if (overlay === 2) {
    return (
    <div className="overlay">
      <div className="overlay__inside">

      <p>The Commission constructed this timeline of what happened using the information set out in its Foundational Documents, which summarized the information available to the Commission at the time they were shared with the public, along with their source materials and addenda and errata. As the Commission’s independent investigation continued, additional information may have come to light that augmented or altered the Commission’s understanding of the facts. The Final Report sets out the Commission’s final best answer to the question of “what happened.” If there is information set out in the Final Report that differs in any way from the What Happened Timeline, the Final Report governs.</p>

        <button className="button"
          onClick={
            () => { setOverlay(3) }
          }
        >Continue</button>
        <p>{overlay}/3</p>
      </div>
    </div>
    )
  } else if (overlay === 3) {
    return (
    <div className="overlay">
      <div className="overlay__inside">


      <p>For more information on the timeline, click the Timeline Information button at the top of the page.</p>

        <button className="button"
          onClick={
            () => { setOverlay(false) }
          }
        >Continue</button>
        <p>{overlay}/3</p>
      </div>
    </div>
    )
  }
  
}

function ScrollBar({scrollPerc, scrollTimeline, setIsBarScrolling, zoomLevel, clearEvent, tickLeft, tickRight}) {

  const [scrollBarPerc, setScrollBarPerc] = useState();

  const ref = useRef();

  const handleStart = () => {
    
    setIsBarScrolling(true);
    
  }

  const handleDrag = (e, data) => {
    //clearEvent();
    // console.log(e);
    // console.log(data);
    // console.log(ref.current.clientWidth);
    const newPerc = data.x / (ref.current.clientWidth - handleWidth);
    setScrollBarPerc(newPerc);
    
    //debounceScrollBarHandler()
    //clearEvent();
    updateScroll();
  }

  const handleStop = () => {
    setIsBarScrolling(false);
    clearEvent();
  }

  const updateScroll = () => {
    scrollTimeline(scrollBarPerc);
  }

  // const debounceScrollBarHandler = useMemo(
  //   () => debounce(updateScroll, 5)
  // , [scrollBarPerc]); 

  let handleWidth = 100;

  let xPos = 0;
  if (scrollPerc > 0) { 
    xPos = scrollPerc * (ref.current.clientWidth - handleWidth);
  } 

  return (
    <div className="scrollBar" ref={ref}>
      <div className="scrollBar__left" onClick={() =>{ tickLeft() }}></div>
      <Draggable
        axis="x"
        bounds="parent"
        defaultPosition={{ x: xPos, y: 0}}
        position={{ x: xPos, y: 0}}
        handle=".scrollBar__handle"
        onStart={() => { handleStart() }}
        onDrag={(e, data) => { handleDrag(e, data) }}
        onStop={() => { handleStop() }}
      >
        
        <div className="scrollBar__handle" style={{ width: handleWidth + 'px' }}>

        </div>
      
      </Draggable>
      <div className="scrollBar__right" onClick={() =>{ tickRight() }}></div>
      </div>
    
  )
}

function TimelineScroll({verticalScroll, setVerticalScroll}) {

  const scrollLevels = [0, 40, 80, 120, 160];

  const minScroll = scrollLevels[0];
  const maxScroll = scrollLevels[scrollLevels.length -1];

  const maxUp = verticalScroll === minScroll ? true : false;
  const maxDown = verticalScroll === maxScroll ? true : false;


  const scrollDown = () => {
    if (!maxDown) {
      let pos = scrollLevels.indexOf(verticalScroll);
      pos++;
      setVerticalScroll(scrollLevels[pos]);
    }
  }

  const scrollUp = () => {
    if (!maxUp) {
      let pos = scrollLevels.indexOf(verticalScroll);
      pos--;
      setVerticalScroll(scrollLevels[pos]);
    }
  }

  return (
    <>
    <div className={`scrollTimeline scrollTimeline--up ${maxUp ? "scrollTimeline--hide" : ""}`} onClick={() => scrollUp()}>

    </div>

    <div className={`scrollTimeline scrollTimeline--down ${maxDown ? "scrollTimeline--hide" : ""}`} onClick={() => scrollDown()}>
      
    </div>
    </>
  ) 
}


function App() {

  const startTimeStr = TIMELINE_START.toLocaleString(DateTime.TIME_WITH_SECONDS);
  const startDateTimeStr = TIMELINE_START.toLocaleString({ month: 'long', day: 'numeric' });

  const [zoom, setZoom] = useState(10); //20
  const [timelines, setTimelines] = useState([]);
  const [currentTime, setCurrentTime] = useState(startTimeStr);
  const [currentDate, setCurrentDate] = useState(startDateTimeStr);
  const [activeEvents, setActiveEvents] = useState([]); // 5
  const [clickedEvent, setClickedEvent] = useState([]); // 6
  const [overlay, setOverlay] = useState(1);
  const [scrollPerc, setScrollPerc] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);
  const [isBarScrolling, setIsBarScrolling] = useState(false);
  const [verticalScroll, setVerticalScroll] = useState(0);
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const params = new URLSearchParams(window.location.search);
  const preview = params.get("preview") ? params.get("preview") : false;

  useEffect(() => {
    window.onresize = () => {
      setWindowSize(window.innerWidth);
    };
    //console.log('hih');
    //window.scrollTo(0,1);
  }, []);

  useEffect(() => {

    const fetchData =  async() => {
      let response = await axios(
        `/api/timeline/${ preview ? preview : 'all'}.json`
      );

      setTimelines(response.data.timelines);
    }

    fetchData()
  }, [preview]);

  
  // Scrolling

  const container = useRef();
  const scroll = useRef();
  const onStartScroll = () => {
    //console.log('start');
    setIsScrolling(true);
    //setActiveEvents([]);
  };
  const onScroll = () => {
    //TODO
    setActiveEvents([]);
    //updateCurrentTime();
  };
  const onEndScroll = (e) => {
    //console.log(e);
    debounceScrollHandler();
    //updateCurrentTime();
    setIsScrolling(false);

  };

  const updateCurrentTime = () => {

    if (container.current) {
      scroll.current = container.current.scrollLeft;
    }

    //console.log(isScrolling);

    const percentPos = (container.current.scrollLeft) / ((timelineSize * zoom));
    const minutesIn = percentPos * totalMinutes;
    const currentDateTime = TIMELINE_START.plus({minutes: minutesIn});

    if (clickedEvent.length === 0) {
    setCurrentTime(currentDateTime.toLocaleString(DateTime.TIME_WITH_SECONDS));
    setCurrentDate(currentDateTime.toLocaleString({ month: 'long', day: 'numeric' }));
    }

    // Only do this if it was NOT triggered by scrollbar?
    if (isBarScrolling === false) {
      setScrollPerc(percentPos);
    }
    
  };

  const debounceScrollHandler = useMemo(
    () => debounce(updateCurrentTime, 200)
  , [container, updateCurrentTime]); 

  
  const scrollTimeline = (percentPos) => {
    const pxLeft = percentPos * ((timelineSize * zoom) - leftPadding);
    if (isIOS) {
      container.current.scrollTo({left: pxLeft});
    } else {
    container.current.scrollTo({left: pxLeft, behavior: 'smooth' });
    }
    setScrollPerc(percentPos);
  }

  const scrollToTime = (startTime) => {
    const startTimeObj = DateTime.fromISO(startTime, {setZone: true});
    // Time in minutes between timeline start and event start
    const eventI = Interval.fromDateTimes(TIMELINE_START, startTimeObj);
    const pxPerMinute =  ((timelineSize * zoom) - leftPadding) / i.length('minutes');
    // console.log(pxPerMinute);
    // console.log(pxPerMinute * (eventI.length('minutes')));
    const pxLeft = (pxPerMinute * (eventI.length('minutes')) + 0.6);

    

    if (isIOS) {
      container.current.scrollTo({ left: pxLeft });

    } else {
      container.current.scrollTo({left: pxLeft, behavior: 'smooth' });

    }
   
    //setTimeout(container.current.scrollTo({top: 0, left: pxLeft}),100);

    //TODO: remove
    setCurrentTime(startTimeObj.toLocaleString(DateTime.TIME_WITH_SECONDS));
    setCurrentDate(startTimeObj.toLocaleString({ month: 'long', day: 'numeric' }));
  }

  // Zooming
  const prevZoom = useRef();
  const percentPos = useRef();

  useEffect(() => {
    if (prevZoom.current) {
      container.current.scrollLeft = ((timelineSize * zoom) - leftPadding) * percentPos.current;
    }
  }, [zoom]);

  const updateZoom = (level) => {
    prevZoom.current = zoom;
    percentPos.current = (container.current.scrollLeft) / ((timelineSize * zoom) - leftPadding);
    setZoom(level);
  }


  // Timeline scroll all current events
  const activateEvents = (event) => {
    //console.log(event);
    if(!activeEvents.includes(event.id)){
      
      setActiveEvents(prevData => ([
        ...prevData,
        event.id
      ]));//newEvents
    }
  }

  // Single event click
  const activateEvent = (event, scroll = true) => {

    setClickedEvent(event);

    if (scroll) {
      scrollToTime(event.startTime);
    }

    
  }

  const timelinesClickHandler = (e) => {
    //!e.target.className.includes('event') &&
    // console.log(e);
    if ( !e.target.className.includes('timeline__title') && !e.target.className.includes('event')) {
      setClickedEvent([]);
    }
  }

  const tickLeft = () => {
    const current = scrollPerc;
    const newPerc = current - 0.001;
    scrollTimeline(newPerc);
  }

  const tickRight = () => {
    const current = scrollPerc;
    const newPerc = current + 0.001;
    scrollTimeline(newPerc);
  }

  const clearEvent = () => {
    setClickedEvent([]);
  }

  const nextEvent = () => {
    const arr = clickedEvent.id.split('-');
    const timelineIndex = arr[0];
    const eventIndex = Number(arr[1]);
    

    const nextEventIndex = eventIndex + 1;
    
    const event = timelines[timelineIndex].events[nextEventIndex];
    const timeline = timelines[timelineIndex];
    const id = `${timelineIndex}-${nextEventIndex}`;
    setClickedEvent({id: id, title: event.title, location: event.location_description, description: event.description_html, foundational_document_url: timeline.foundational_document_url, isEstimate: event.is_start_time_estimated, startTime: event.start_time_iso8601, endTime: event.end_time_iso8601 })
    
    scrollToTime(event.start_time_iso8601);
    // scroll to event
  }

  const prevEvent = () => {
    const arr = clickedEvent.id.split('-');
    const timelineIndex = arr[0];
    const eventIndex = Number(arr[1]);
    

    const nextEventIndex = eventIndex - 1;
    
    const event = timelines[timelineIndex].events[nextEventIndex];
    const timeline = timelines[timelineIndex];
    const id = `${timelineIndex}-${nextEventIndex}`;
    setClickedEvent({id: id, title: event.title, location: event.location_description, description: event.description_html, foundational_document_url: timeline.foundational_document_url, isEstimate: event.is_start_time_estimated, startTime: event.start_time_iso8601, endTime: event.end_time_iso8601 })
    scrollToTime(event.start_time_iso8601);
  }

  const scrollIndicatorActive = clickedEvent.length === 0 ? true : false;


  return (

    <>

    <HeaderBar />

    <div className="wrapper">

      {overlay ? <Warning overlay={overlay} setOverlay={setOverlay} /> : false}

      <div className={`timelines-wrap ${overlay ? "timelines-wrap--blurred" : ""}`}>

      <h1 className="timelines-wrap__title">Timelines</h1>

      

      <ScrollBar scrollPerc={scrollPerc} setIsBarScrolling={setIsBarScrolling} scrollTimeline={scrollTimeline} zoomLevel={zoom} clearEvent={clearEvent} tickLeft={tickLeft} tickRight={tickRight} /> 

      {/* <TimelineScroll verticalScroll={verticalScroll} setVerticalScroll={setVerticalScroll} /> */}

      <ScrollContainer 
        className="scroll-container" 
        innerRef={container}
        onStartScroll={onStartScroll}
        onScroll={onScroll}
        onEndScroll={onEndScroll}
        vertical={windowSize <= 1100 ? true : false}
        horizontal={false}
        nativeMobileScroll={false}
        // vertical={windowSize <= 1100 ? true : false}
        // horizontal={true}
      >

      <div className={`scrollIndicator ${scrollIndicatorActive ? "" : "scrollIndicator--inactive"}`} onClick={(e) => timelinesClickHandler(e)}>
        <div className="scrollIndicator__currentTime">
          <span className="scrollIndicator__currentTime__date">{currentDate}</span>
          <span className="scrollIndicator__currentTime__time">{currentTime}</span>
        </div>
      </div>

      <div className="timelines" style={{ width: zoom * timelineSize + 'px' }} onClick={(e) => timelinesClickHandler(e)}> 

      

        <div className="timelines__indicator">
        {arrayOfTime.map((interval) => {
          const start = interval.start;

          // Show indicators: hour, 15m, 5m - based on zoom level
          // 5m 
          let showIndicator = zoom > 19 ? true : false;

          // 15m
          if ([15, 30, 45].includes(start.minute) && zoom > 6) {
            showIndicator = true;
          }

          // Always show hourly
          if (start.minute === 0) {
            showIndicator = true;
          }

          if (showIndicator) {
            return (
              <Indicator key={start} time={start}  />
            );
          } else {
            return null;
          }
          
        })}
          
        </div>

        {timelines.length > 0 ? (
          <div className={`timelines__wrapper ${isScrolling ? "is-scrolling" : ""}`} style={{ marginTop: '-' + verticalScroll + 'px'}}> 
          {timelines.map((timeline, index) => {

            const events = timeline.events.map((event, key) => {
              const id = `${index}-${key}`
              return (
                {id: id, title: event.title, location: event.location_description, description: event.description_html, foundational_document_url: timeline.foundational_document_url, isEstimate: event.is_start_time_estimated, startTime: event.start_time_iso8601, endTime: event.end_time_iso8601 }
              );
            })

            return (
              <Timeline key={`${index}`} id={`${index}`} title={timeline.title} events={events} clickedEvent={clickedEvent} activateEvent={activateEvent} activeEvents={activeEvents} activateEvents={activateEvents} zoom={zoom} scrollPos={container.current.scrollLeft} />
            );
            
          })}
          </div>
        ) : (
          <p className="loading">Loading...</p>
        )}

      </div>
      

    </ScrollContainer>

      <InfoPane 
        currentDate={currentDate} 
        currentTime={currentTime} 
        timelines={timelines}
        activeEvents={activeEvents} 
        activateEvent={activateEvent} 
        clickedEvent={clickedEvent} 
        clearEvent={clearEvent} 
        nextEvent={nextEvent} 
        prevEvent={prevEvent} 
      />

      {/* <ZoomControl zoom={zoom} setZoom={updateZoom} /> */}


    </div>

    <div className="debugBar">


{/* <h2>Debug info</h2> */}

      {/* <p>Zoom level: {zoom}</p>  


    Timeline total size: { timelineSize * zoom }px <br />

    <p>{ i.length('minutes') / (timelineSize * zoom) } minutes per px</p>  */}

{/* <p>px per minute: {((timelineSize * zoom) - leftPadding) / i.length('minutes') }</p> */}
{/* 15 minute increments: { arrayOfTime.length } <br /> */}

{/* <p>Events at active time
        

</p> */}

{/* Total hours in timeline: { i.length('hours') } <br />

Total minutes in timeline { i.length('minutes') } <br />  */}



    </div>

    </div>

    </>
    
  );
}

export default App;
