import React, { Component } from 'react'
import { useState } from 'react';

import logo from '../logo.svg';

function MobileMenu() {

  const [open, setOpen] = useState(false);

  return (
    <div className={`mobileMenu ${open ? "mobileMenu--open" : ""}`}>
      <button className="mobileMenu__toggle"
        onClick={
          () => { setOpen( !open ) }
        }>
          Menu
        <span className="mobileMenu__toggle__icon"></span>
      </button>

      <div className="mobileMenu__inside">
      <a href="https://masscasualtycommission.ca/whathappened/" className="button hollow">Timeline Information</a>
              <a href="https://masscasualtycommission.ca/support" className="button hollow">Wellness Support</a>
              <a href="https://masscasualtycommission.ca/" target="_parent" className="button">Return to Main Website</a>
              <a href="https://www.google.com/" className="button hollow quickExit">Exit website</a>
              </div>
    </div>
  )
  
}

export default class HeaderBar extends Component {

  render() {


    const zoomIn = () => {
      // let pos = zoomLevels.indexOf(zoom);
      // pos++;
      // setZoom(zoomLevels[pos]);
    }



    return (
      <div id="responsive-header">
        	<a href="https://masscasualtycommission.ca/" className="logo" title="Homepage - Mass Casualty Commission">
							<img src={logo} />
						</a>
				
            <MobileMenu />
								
            <div id="headerLinks">
              <a href="https://masscasualtycommission.ca/whathappened" className="button hollow">Timeline Information</a>
              <a href="https://masscasualtycommission.ca/support" className="button hollow">Wellness Support</a>
              <a href="https://www.google.com/" className="button hollow quickExit">Exit website</a>
            </div>


      </div>
    )
  }
}
